import styled from 'styled-components';
import {
	mediaQuery,
	setColors,
	setFontFamily,
	setFontSize,
	setFontWeight,
} from '../../../../utils/helpers';

export const Wrapper = styled.article`
	display: grid;
	grid-template-columns: 1fr;
	align-self: flex-start;

	img {
		border-radius: 7px;
		margin-bottom: 1rem;
	}

	h2 {
		font-family: ${setFontFamily.bold} !important;
		font-size: ${setFontSize.littleSmall};
		font-weight: ${setFontWeight.bold} !important;
		color: ${setColors.lightBlack};
		letter-spacing: -1px;
		line-height: 1.4;
		margin: 0;
		padding: 0 !important;
		${mediaQuery.laptopXL`font-size: ${setFontSize.tiny} !important;`};
		${mediaQuery.tabletL`font-size: ${setFontSize.veryTiny} !important;`};
		${mediaQuery.mobileXL`font-size: ${setFontSize.tiny} !important;`};
		display: block;
		display: -webkit-box;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
		overflow: hidden;
		margin-top: ${ (props) => (!props.isBlogPost) ? '15px' : '0' };
	}

	p {
		padding: 20px 0 9px 0;
		margin: 0;
		text-transform: uppercase;
		font-family: ${setFontFamily.book};
		font-size: ${setFontSize.veryTiny};
		font-weight: ${setFontWeight.normal};
		color: ${setColors.lightBlack};
		line-height: 1.43;
		${mediaQuery.laptopXL`font-size: ${setFontSize.veryTiny};`};
		${mediaQuery.tabletL`font-size: ${setFontSize.tiniest};`};
		${mediaQuery.mobileXL`font-size: ${setFontSize.tiniest};`};
	}

	a {
		text-decoration: none;
		color: black;
	}
`;
