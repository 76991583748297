import styled from 'styled-components';
import {
	mediaQuery,
	setColors,
	setFontFamily,
	setFontSize,
} from '../../../../utils/helpers';

export const AuthorBioContainer = styled.div`
	margin: 70px auto 30px;
`;

export const StyledHr = styled.div`
	height: 1px;
	width: 100%;
	background-color: ${setColors.hrTagColor};
`;

export const StyledHeader = styled.h3`
	color: ${setColors.lightBlack};
	margin-bottom: 0px;
	${mediaQuery.mobileM`
		text-align:center;
	`}
`;

export const AuthorDetails = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 20px;
	div:first-child {
		align-self: flex-start;
	}
	${mediaQuery.mobileM`
		flex-direction: column;
		div:first-child {
			align-self: center;
		}	
	`};
`;

export const DetailsContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 60%;
	font-size: ${setFontSize.tiniest};
	padding: 0 15px;
	${mediaQuery.mobileM`
		margin-top: 15px;
		align-items: center;
		justify-content: center;
		width: 70%;
	`}
	${mediaQuery.mobileXS`
		width: 85%
	`}
	${mediaQuery.mobileXXS`
		width: 100%
	`}
`;

export const BlogAuthorName = styled.div`
	display: flex;
	font-size: ${setFontSize.littleTiny};
	color: ${setColors.lightBlack};
	align-items: center;

	div:first-child {
		margin-right: 15px;
	}
`;

export const BlogAuthorBio = styled.div`
	p {
		font-size: ${setFontSize.veryTiny} !important;
		font-family: ${setFontFamily.book};
	}
	${mediaQuery.tabletL`
		p{
			font-size: ${setFontSize.veryTiny} !important;
		}
	`}
	${mediaQuery.tabletXS`
		p{
			font-size: ${setFontSize.minute} !important;
		}
	`}
	${mediaQuery.mobileM`
		p{
			font-size: ${setFontSize.veryTiny} !important;
		}
	`}
`;

export const StyledAnchor = styled.a`
	margin-right: 2px;
	height: 25px;
	width: 20px;
	border-radius: 50%;
	background-color: ${setColors.white};
	position: relative;
	border-bottom: none !important;

	svg {
		&:hover {
			color: ${props =>
				props.linkedIn
					? 'rgb(2, 112, 173) !important'
					: 'rgb(67, 156, 214) !important'};
		}
	}
`;

export const MorePosts = styled.a`
	cursor: pointer;
	border-bottom: none !important;
`;

export const AuthorName = styled.div`
	font-size: ${setFontSize.littleTiny};
	font-family: ${setFontFamily.bold};
	${mediaQuery.mobileM`
		font-size: ${setFontSize.littleTiny};
	`}
`;

export const StyledFlex = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;

	svg {
		margin-left: 4px;
		margin-top: -2.3px;
	}
`;
