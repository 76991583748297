import styled, { css } from 'styled-components';
import {
	mediaQuery,
	setColors,
	setFontFamily,
	setFontSize,
} from '../../../../utils/helpers';

export const Navbar = styled.nav`
	max-width: 300px;
	position: sticky;
	left: 0;
	top: 80px;
	max-height: 650px;
	overflow-y: scroll;
	margin-left: -340px;

	${mediaQuery.tabletXS`
		display: none;	
	`}

	${mediaQuery.tabletL`
		max-width: 250px
	`}

	& ::-webkit-scrollbar {
		width: 5px;
	}
	& ::-webkit-scrollbar-track {
		background: ${setColors.white};
	}
	& ::-webkit-scrollbar-thumb {
		border-radius: 5px;
		background: ${setColors.lightGray};
	}
	& ::-webkit-scrollbar-thumb:hover {
		background: ${setColors.lightGray};
	}
`;

export const UnorderedList = styled.ul`
	list-style-type: none;
`;

export const List = styled.li``;

export const StyledLink = styled.div`
	font-size: ${setFontSize.veryVeryTiny};
	font-family: ${setFontFamily.book};
	color: ${props =>
		props.isActive ? setColors.themeBlue : setColors.planCardGrey};
	cursor: pointer;
	text-decoration: none;
	max-width: 250px;
	:hover {
		color: ${props => !props.isActive && `${setColors.lightBlack}`};
	}

	${mediaQuery.tabletL`
		font-size: ${setFontSize.veryVeryTiny};
		max-width: 180px
	`}

	${props => {
		switch (props.nodeType) {
			case 'heading-2':
				return css`
					padding: 1px 5px 4px 15px;
				`;
			case 'heading-3':
				return css`
					padding: 1px 5px 4px 30px;
				`;
			default:
		}
	}}
`;

export const StyledDiv = styled.div`
	color: ${setColors.lightBlack};
	font-size: ${setFontSize.mediumTiny};
	font-family: ${setFontFamily.bold};
	margin-left: 19%;
	padding: 0 0 8px 0;
`;
