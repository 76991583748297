import React, { useMemo, useState } from 'react';
import { useHeadingsObserver } from '../../../hooks/useHeadingsObserver';
import { Navbar, UnorderedList, StyledLink, StyledDiv } from './styles';

const TOCRenderer = ({ content }) => {
	const [activeState, setActiveState] = useState({});
	const { activeId } = useHeadingsObserver('');

	useMemo(() => {
		if (activeId) {
			setActiveState({ [activeId]: true });
		}
	}, [activeId]);

	return (
		<Navbar id="table-of-content">
			<StyledDiv>Table of Content</StyledDiv>
			<UnorderedList>
				{content.map((item, index) => {
					return (
						<StyledLink
							id={index}
							isActive={activeState[item.id]}
							nodeType={item.nodeType}
							className={item.id}
							onClick={() => {
								setActiveState({ [item.id]: true });
								document.getElementById(`${item.id}`).scrollIntoView({
									behavior: 'smooth',
									block: 'start',
									inline: 'nearest',
								});
							}}
						>
							{item.value}
						</StyledLink>
					);
				})}
			</UnorderedList>
		</Navbar>
	);
};

export default TOCRenderer;
