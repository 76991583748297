import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { CONSTANTS } from '../../../../utils/constants';
import * as S from './styles';

const Social = ({ content, direction }) => {
	return (
		<S.SocialWrapper direction={direction}>
			{content.map(el => {
				return (
					<Link to={`${el.link}/`} target="_blank">
						{CONSTANTS.SOCIAL_MEDIA_OBJECT[el.type]}
					</Link>
				);
			})}
		</S.SocialWrapper>
	);
};

Social.propTypes = {
	direction: PropTypes.string,
	content: PropTypes.array,
};

Social.defaultProps = {
	direction: 'vertical',
	content: [],
};
export default Social;
