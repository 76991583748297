import React from 'react';
import Img from 'gatsby-image';
import { TiSocialLinkedin } from 'react-icons/ti';
import { AiOutlineTwitter } from 'react-icons/ai';
import {
	AuthorBioContainer,
	AuthorDetails,
	AuthorName,
	BlogAuthorBio,
	BlogAuthorName,
	DetailsContainer,
	MorePosts,
	StyledAnchor,
	StyledFlex,
	StyledHeader,
	StyledHr,
} from './styles';
import { CONSTANTS } from '../../../../utils/constants';
import { setColors } from '../../../../utils/helpers';
import RichTextRenderer from '../RichTextRenderer';
import NewTabIcon from '../../../svg/new-tab-icon.svg';

const AuthorBio = ({
	firstName,
	lastName,
	linkedInUrl,
	twitterUrl,
	shortBio,
	profilePicture,
	slug,
}) => {
	return (
		<AuthorBioContainer>
			<StyledHr />
			<StyledHeader>{CONSTANTS.ABOUT_AUTHOR_TEXT}</StyledHeader>
			<AuthorDetails>
				<Img
					fluid={profilePicture.fluid}
					key={profilePicture.id}
					alt={profilePicture.title}
					style={{
						width: '100px',
						height: '100px',
						borderRadius: '50%',
						border: `${setColors.themeBlue}`,
					}}
				/>
				<DetailsContainer>
					<BlogAuthorName>
						<AuthorName className={AuthorName}>
							{firstName} {lastName}
						</AuthorName>
						{linkedInUrl && (
							<StyledAnchor
								href={linkedInUrl}
								target="_blank"
								className="linkedin"
								linkedIn
							>
								<TiSocialLinkedin
									style={{
										position: 'absolute',
										color: `${setColors.white}`,
										backgroundColor: `${setColors.planCardGrey}`,
										left: '5px',
										top: '4px',
										borderRadius: '3px',
										cursor: 'pointer',
									}}
								/>
							</StyledAnchor>
						)}
						{twitterUrl && (
							<StyledAnchor
								href={twitterUrl}
								target="_blank"
								className="twitter"
								twitter
							>
								<AiOutlineTwitter
									style={{
										position: 'absolute',
										color: `${setColors.planCardGrey}`,
										left: '5px',
										top: '4px',
										cursor: 'pointer',
									}}
								/>
							</StyledAnchor>
						)}
					</BlogAuthorName>
					<BlogAuthorBio>
						<RichTextRenderer content={shortBio} />
					</BlogAuthorBio>
					<MorePosts href={`/posts/${slug}`} target="_blank">
						<StyledFlex>
							{CONSTANTS.POST_FROM_THIS_AUTHOR}
							<NewTabIcon />
						</StyledFlex>
					</MorePosts>
				</DetailsContainer>
			</AuthorDetails>
			<StyledHr />
		</AuthorBioContainer>
	);
};

export default AuthorBio;
