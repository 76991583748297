import { useEffect, useRef, useState } from 'react';

export const useHeadingsObserver = initialValue => {
	const observer = useRef();
	const [activeId, setActiveId] = useState(initialValue);
	useEffect(() => {
		const handleObsever = entries => {
			entries.forEach(entry => {
				if (entry?.isIntersecting) {
					setActiveId(entry.target.id);
				}
			});
		};

		observer.current = new IntersectionObserver(handleObsever, {
			rootMargin: '-5% 0px -85% 0px',
		});

		const elements = document.querySelectorAll('h2, h3');
		elements.forEach(elem => observer.current.observe(elem));

		return () => observer.current?.disconnect();
	}, []);

	return { activeId };
};
