import React, { useEffect, useRef, useState } from 'react';
import { graphql } from 'gatsby';
import InfoBox from '../components/global/info-box/InfoBox';
import Layout from '../components/global/layout/Layout';
import RichTextRenderer from '../components/global/RichTextRenderer';
import Social from '../components/global/social/Social';
import SEO from '../components/global/seo';
import PostHolder from '../components/blog-page/post-holder/PostHolder';
import CTA from '../components/global/CTA/CTA';
import AuthorBio from '../components/global/authorBio/AuthorBio';
import * as S from '../components/blog-post-template/styles';
import { Container } from '../components/global/customers/styles';
import { CONSTANTS } from '../../utils/constants';
import { CTAWrapper } from '../components/integrations-page/styles';
import TOCRenderer from '../components/global/RichTextRendererForToc/TOCRenderer';
const BlogPost = ({ data }) => {
	const {
		coverImage,
		title,
		category,
		slug,
		timeToRead,
		publishingDate,
		bodyRichText,
		seoTitle,
		seoDescription,
		seoArticle,
		seoJsonSchema,
		authorBio,
	} = data.contentfulBlogPost;
	const [scroll, setScroll] = useState(0);
	const [blogPublishedDate, setBlogPublishedDate] = useState('');
	const blogContent = useRef(JSON.parse(bodyRichText.raw));
	const refernceIndex = useRef(0);
	const [tocHeadings, setTocHeadings] = useState([]);
	const posts = data.allContentfulBlogPost.nodes;
	const socialShare = CONSTANTS.SOCIAL_SHARE_ARRAY.map(el => {
		return {
			link: el.link
				.replace('page_title', encodeURI(seoTitle.split('-')[0].trim()))
				.replace('page_url', `${CONSTANTS.COMMON_BLOG_PRE_URL}${slug}`),
			type: el.type,
		};
	});
	const onScroll = () => {
		const scrolled =
			document.documentElement.scrollTop || document.body.scrollTop;
		const maxHeight =
			(document.documentElement.scrollHeight || document.body.scrollHeight) -
			document.documentElement.clientHeight;
		const scrolledPercentage = (scrolled / maxHeight) * 100;
		setScroll(scrolledPercentage);
	};
	useEffect(() => {
		window.addEventListener('scroll', onScroll);
		const dateArray = publishingDate.split('-');
		setBlogPublishedDate(`${dateArray[1]} ${dateArray[0]}, ${dateArray[2]}`);
		return () => window.removeEventListener('scroll', onScroll);
	}, [publishingDate]);

	// performing following steps to modify the content from contenful and add field of unique id for h2 and h3 tags
	useEffect(() => {
		window.scrollTo(0, 0);
		blogContent.current.content.forEach((item, index) => {
			switch (item.nodeType) {
				case 'embedded-asset-block':
				case 'embedded-entry-block':
					blogContent.current.content[index] = {
						...blogContent.current.content[index],
						data: {
							...blogContent.current.content[index].data,
							target: {
								...blogContent.current.content[index].data.target,
								...bodyRichText.references[refernceIndex.current],
							},
						},
					};
					refernceIndex.current += 1;
					break;
				case 'heading-2':
				case 'heading-3':
					let value = '';
					if (
						item.content.length > 1 &&
						item.content[1].nodeType === 'hyperlink'
					)
						value = `${item?.content[0]?.value} ${item?.content[1].content[0].value}`;
					else value = item?.content[0]?.value;
					setTocHeadings(prev => [
						...prev,
						{
							nodeType: item.nodeType,
							value,
							id: `${item.nodeType}-${index}`,
						},
					]);
					blogContent.current.content[index] = {
						...blogContent.current.content[index],
						// adding unique id
						id: `${item.nodeType}-${index}`,
					};
					break;
				default:
				//pass
			}
		});
	}, []);

	return (
		<Layout>
			<SEO
				title={seoTitle}
				description={seoDescription}
				image={coverImage}
				article={seoArticle}
				seoJsonSchema={seoJsonSchema}
			/>
			{scroll ? (
				<S.ProgressBarContainer>
					<S.ProgressBar style={{ width: `${scroll}%` }}></S.ProgressBar>
				</S.ProgressBarContainer>
			) : (
				''
			)}
			<Container>
				<Social content={socialShare} />
				<InfoBox
					image={coverImage}
					alt={coverImage.title}
					blogCategory={category}
					publisher={{
						authorName: `${authorBio.firstName} ${authorBio.lastName}`,
						authorImage: authorBio ? authorBio.profilePicture : '',
						publishingDate: blogPublishedDate,
						timeToRead: `${timeToRead} min read`,
					}}
					title={title}
					blogPost
					isHeroHeader
					changeOrder={2}
				/>
				<S.BlogContentWrapper>
					<TOCRenderer content={tocHeadings} />
					<RichTextRenderer
						blogContent
						content={{
							refernces: bodyRichText.references,
							raw: JSON.stringify(blogContent.current),
						}}
					/>
					{authorBio && (
						<AuthorBio
							firstName={authorBio.firstName}
							lastName={authorBio.lastName}
							slug={authorBio.slug}
							profilePicture={authorBio.profilePicture}
							linkedInUrl={authorBio.linkedinUrl}
							shortBio={authorBio.shortBio}
							twitterUrl={authorBio.twitterUrl}
						/>
					)}
				</S.BlogContentWrapper>
				<Social content={socialShare} direction="horizontal" />
				{posts.length !== 0 && (
					<S.RelatedBlogWrapper>
						<PostHolder
							title="You may also like..."
							data={posts}
							latest="true"
						/>
					</S.RelatedBlogWrapper>
				)}
			</Container>
			<S.BlogCTAWrapper>
				<CTAWrapper>
					<CTA
						title={CONSTANTS.BLACK_CTA_TITLE}
						subtitle={CONSTANTS.BLACK_CTA_SUBTITLE}
					/>
				</CTAWrapper>
			</S.BlogCTAWrapper>
		</Layout>
	);
};
export const query = graphql`
	query($slug: String, $relatedBlog: [String]) {
		contentfulBlogPost(slug: { eq: $slug }) {
			authorBio {
				firstName
				lastName
				slug
				profilePicture {
					id
					fluid {
						...GatsbyContentfulFluid
					}
					title
				}
				linkedinUrl
				twitterUrl
				shortBio {
					raw
				}
			}
			coverImage {
				title
				fluid(maxHeight: 488, maxWidth: 488) {
					...GatsbyContentfulFluid
				}
				file {
					url
					details {
						image {
							height
							width
						}
					}
				}
			}
			bodyRichText {
				raw
				references {
					... on ContentfulAsset {
						contentful_id
						__typename
						fluid {
							...GatsbyContentfulFluid
						}
						file {
							details {
								image {
									height
									width
								}
							}
						}
						title
					}
					... on ContentfulVideoEmbed {
						__typename
						contentful_id
						videoUrl
						host
					}
				}
			}
			category
			slug
			title
			timeToRead
			publishingDate(formatString: "DD-MMM-YYYY")
			seoTitle
			seoDescription
			seoArticle
			seoJsonSchema {
				internal {
					content
				}
			}
		}
		allContentfulBlogPost(
			filter: { slug: { in: $relatedBlog } }
			sort: { order: DESC, fields: createdAt }
		) {
			nodes {
				slug
				timeToRead
				title
				category
				coverImage {
					fluid {
						...GatsbyContentfulFluid
					}
					title
				}
			}
		}
	}
`;
export default BlogPost;
