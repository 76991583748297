import styled from 'styled-components';
import {
	mediaQuery,
	setColors,
	setFontFamily,
	setFontSize,
	setFontWeight,
} from '../../../../utils/helpers';

export const Wrapper = styled.article`
	display: grid;
	grid-template-columns: 1fr;
	align-self: flex-start;

	img {
		border-radius: 7px;
		margin-bottom: 1rem;
	}

	h2 {
		font-family: ${setFontFamily.bold} !important;
		font-size: ${setFontSize.littleSmall};
		font-weight: ${setFontWeight.bold} !important;
		color: ${setColors.secondaryBlack};
		letter-spacing: -1px;
		line-height: 1.4;
		margin: 0;
		padding: 0 !important;
		${mediaQuery.laptopXL`font-size: ${setFontSize.small} !important;`};
		${mediaQuery.tabletL`font-size: ${setFontSize.verySmall} !important;`};
		${mediaQuery.mobileXL`font-size: ${setFontSize.mediumSmall} !important;`};
		display: block;
		display: -webkit-box;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
		overflow: hidden;
		margin-top: 12px;
    }

	a {
		text-decoration: none;
		color: black;
	}
`;
