import React from 'react';
import Img from 'gatsby-image';
import * as S from './styles';
import { Link } from 'gatsby';

const CompareSnippet = ({ title, src, category, readingTime, slug, alt }) => {
	return (
		<S.Wrapper>
			<Link to={`${slug}/`}>
				<Img fluid={src} alt={alt} />
			</Link>
			<Link to={`${slug}/`}>
				<h2>{title}</h2>
			</Link>
		</S.Wrapper>
	);
};

export default CompareSnippet;
