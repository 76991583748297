import styled from 'styled-components';
import {
	mediaQuery,
	setColors,
	setFontFamily,
	setFontSize,
	setFontWeight,
} from '../../../utils/helpers';

export const BlogContentWrapper = styled.div`
	padding: 30px 0 0;
	margin: 0 0 0 300px;

	font-size: ${setFontSize.tiny};
	color: ${setColors.lightBlack};
	font-family: ${setFontFamily.book};
	line-height: 1.8 !important;
	box-sizing: border-box;

	${mediaQuery.laptopXL`
        font-size: ${setFontSize.tiny};
        p{font-size: ${setFontSize.tiny};}
        `}
	${mediaQuery.tabletL`
        font-size: ${setFontSize.veryTiny}; 
        p{font-size: ${setFontSize.veryTiny};}
       `}
    ${mediaQuery.mobileXL`padding: 0;
		p{
			font-size: ${setFontSize.tiny};
		}
	`}

	${mediaQuery.tabletXS`
		max-width: 800px;
		padding: 30px 0 0;
		margin: 0 auto;
	`}

    a {
		color: ${setColors.themeBlue};
		border-bottom: 1px solid ${setColors.themeBlue};
		text-decoration: none;
	}

	p {
		margin: 0 0 1.5em 0 !important;
		strong {
			font-family: ${setFontFamily.bold};
		}
	}

	li > p {
		margin: 0 !important;
		line-height: 1.8;
	}

	b {
		font-family: ${setFontFamily.bold};
		color: ${setColors.lightBlack} !important;
		font-weight: ${setFontWeight.extraBold};
	}

	i {
		font-family: ${setFontFamily.book};
		color: inherit;
	}

	blockquote p {
		padding: 0;
		display: inline;
	}

	blockquote {
		padding: 10px 15px 0 !important;
		font-family: ${setFontFamily.book};
		margin: 0;
		margin-bottom: 1.5em;
		line-height: 1.6;
		font-size: ${setFontSize.mediumSmall};
		color: ${setColors.lightBlack} !important;
		font-style: italic;
		border-left: 7px solid ${setColors.lightBlack};

		${mediaQuery.laptopXL` font-size: ${setFontSize.tinySmall};`}
		${mediaQuery.tabletL`font-size: ${setFontSize.tiny};`}
        ${mediaQuery.mobileXL`font-size: ${setFontSize.tiny};`}
	}

	blockquote::before {
		content: '\“';
	}

	blockquote::after {
		content: '\”';
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		scroll-margin-top: 80px;
	}

	h1 {
		color: ${setColors.lightBlack} !important;
		font-size: ${setFontSize.mediumLarge48};
		line-height: 62px;
		font-weight: ${setFontWeight.extraBold};
		font-family: ${setFontFamily.black};
		text-transform: none;
		letter-spacing: -1.25px;
		margin: 25px 0;

		${mediaQuery.laptopXL`font-size: ${setFontSize.mediumLarge};`}
		${mediaQuery.tabletL`font-size: ${setFontSize.tinyLarge};`}
        ${mediaQuery.mobileXL`font-size: ${setFontSize.tinyLarge};`}
	}

	h2 {
		color: ${setColors.lightBlack} !important;
		font-size: ${setFontSize.large};
		line-height: 40px;
		font-weight: ${setFontWeight.bold};
		font-family: ${setFontFamily.bold};
		text-transform: none;
		letter-spacing: -0.5px;
		margin: 25px 0;
		${mediaQuery.laptopXL`font-size: ${setFontSize.small};`}
		${mediaQuery.tabletL`font-size: ${setFontSize.mediumSmall};`}
        ${mediaQuery.mobileXL`font-size: ${setFontSize.small};`}
	}

	h3 {
		color: ${setColors.lightBlack} !important;
		font-size: ${setFontSize.smaller};
		line-height: 32px;
		font-weight: ${setFontWeight.bold};
		font-family: ${setFontFamily.bold};
		text-transform: none;
		letter-spacing: 0;
		margin: 5px 0;
		${mediaQuery.laptopXL`font-size: ${setFontSize.tinySmall};`}
		${mediaQuery.tabletL`font-size: ${setFontSize.verySmall};`}
        ${mediaQuery.mobileXL`font-size: ${setFontSize.tinySmall};`}
	}

	h4 {
		color: ${setColors.lightBlack} !important;
		font-size: ${setFontSize.verySmall};
		line-height: 30px;
		font-weight: ${setFontWeight.normal};
		font-family: ${setFontFamily.bold};
		text-transform: none;
		letter-spacing: 0;
		margin: 5px 0;
		${mediaQuery.laptopXL`font-size: ${setFontSize.tiny};`}
		${mediaQuery.tabletL`font-size: ${setFontSize.mediumTiny};`}
        ${mediaQuery.mobileXL`font-size: ${setFontSize.tiny};`}
	}

	h5 {
		color: ${setColors.lightBlack} !important;
		font-size: ${setFontSize.veryTiny};
		line-height: 20px;
		font-weight: ${setFontWeight.normal};
		font-family: ${setFontFamily.medium};
		text-transform: none;
		letter-spacing: 0;
		margin: 5px 0;
		${mediaQuery.laptopXL`font-size: ${setFontSize.veryTiny}`}
		${mediaQuery.tabletL`font-size: ${setFontSize.veryTiny};`}
        ${mediaQuery.mobileXL`font-size: ${setFontSize.veryTiny};`}
	}

	h6 {
		color: ${setColors.lightBlack} !important;
		font-size: ${setFontSize.tiniest};
		line-height: 18px;
		font-weight: ${setFontWeight.normal};
		font-family: ${setFontFamily.medium};
		text-transform: none;
		letter-spacing: 0;
		margin: 5px 0;
		${mediaQuery.laptopXL`font-size: ${setFontSize.tiniest}`}
		${mediaQuery.tabletL`font-size: ${setFontSize.tiniest};`}
        ${mediaQuery.mobileXL`font-size: ${setFontSize.tiniest};`}
	}

	ul {
		margin: 0;
		margin-bottom: 1.8em;
		${mediaQuery.mobileXL`
			margin-left: -20px;
		`}
	}

	ul li {
		padding: 0;
		margin-bottom: 10px;
	}

	img {
		width: 100%;
		border-radius: 10px;
	}
	.imgWrapper {
		margin-bottom: 1.5em;
	}
`;

export const RelatedBlogWrapper = styled.div`
	${mediaQuery.mobileXL`padding: 39px 0;`}
`;

export const BlogCTAWrapper = styled.div`
	background: ${setColors.white};
	position: relative;
	z-index: 1;
	padding: 27px 0 20px 0;
	${mediaQuery.laptopM`padding: 27px 0 20px 0;`}
`;

export const ProgressBar = styled.div`
	height: 6px;
	border-radius: 20px;
	background: ${setColors.themeBlue};
`;

export const ProgressBarContainer = styled.div`
	position: fixed;
	height: 6px;
	background-color: ${setColors.progressBgColor};
	z-index: 100;
	border-radius: 20px;
	width: 100%;
`;
