import styled from 'styled-components';
import { mediaQuery, setColors } from '../../../../utils/helpers';

const horizontalStyle = `
display: none;
flex-wrap: wrap;`;

const verticalStyle = `
display: flex;
position: fixed;
top: 150px;
left: 40px;
flex-direction: column;`;

export const SocialWrapper = styled.div`
	${props =>
		props.direction === 'horizontal' ? horizontalStyle : verticalStyle}

	justify-content: center;

	a {
		display: flex;
		align-self: center;
		height: 24px;
		border: 0 !important;
		margin: ${props =>
			props.direction === 'horizontal' ? '0 15px' : '15px 0'};
	}
	svg {
		height: 24px;
		width: ${props => (props.direction === 'horizontal' ? '24px' : '20px')};
	}
	.facebook {
		width: 15px;
		&:hover > path {
			fill: ${setColors.fbBlue} !important;
		}
	}
	.linkedin {
		&:hover > path {
			fill: ${setColors.linkedinBlue} !important;
		}
	}
	.twitter {
		&:hover > path {
			fill: ${setColors.twitterBlue} !important;
		}
	}
	.reddit {
		&:hover > path {
			fill: ${setColors.redditOrange} !important;
		}
	}
	${mediaQuery.tabletL`left: 15px;`}
	${mediaQuery.mobileXL`display: ${props =>
		props.direction === 'horizontal' ? 'flex' : 'none'};`}
`;
