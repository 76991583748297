import React from 'react';
import BlogSnippet from '../blog-snippet/BlogSnippet';
import * as S from './styles';
import '../../../fonts/typography.css';
import CompareSnippet from '../../comparison-homepage/compare-snippet/ComparePost';

const PostHolder = ({ data, title, subtitle, latest, search, searchTerm, isComparisonPosts=false }) => {

	const Post = ( {post} ) => {
		if(isComparisonPosts){
			return <CompareSnippet 
						key={post.title}
						title={`WotNot vs ${post.title}`}
						src={post.coverImage.fluid}
						slug={`/${post.slug}`}
						alt={post.coverImage.title}
					/>
		}else{
			return <BlogSnippet
						key={post.title}
						title={post.title}
						src={post.coverImage.fluid}
						readingTime={post.timeToRead}
						category={post.category[0]}
						slug={`/blog/${post.slug}`}
						alt={post.coverImage.title}
					/>
		}
	}

	return (
		<S.Wrapper latest={latest}>
			{(subtitle && (
				<S.SubTitle className="font-book-46">{subtitle[0]}</S.SubTitle>
			)) || <h3>{title}</h3>}

			{search && (
				<S.Search>
					{`${search} `}
					<S.SearchTerm>{searchTerm}</S.SearchTerm>
				</S.Search>
			)}

			<section>
				{data.map(post => (
					<Post post={post}/>
				))}
			</section>
		</S.Wrapper>
	);
};

export default PostHolder;
