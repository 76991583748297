import styled from 'styled-components';
import {
	mediaQuery,
	setColors,
	setFontFamily,
	setFontSize,
	setFontWeight,
	media,
} from '../../../../utils/helpers';

export const Wrapper = styled.section`
	h1,
	h3 {
		font-family: ${setFontFamily.bold};
		margin: 0;
		margin-top: 70px;
		color: ${setColors.lightBlack};
		margin-bottom: 50px;
		font-size: ${setFontSize.large};
		line-height: 1.2;
		font-weight: ${setFontWeight.extraBold};
	}
	section {
		display: grid;
		padding: 0 21px;
		grid-template-columns: 1fr 1fr 1fr;
		column-gap: 72px;
		row-gap: 70px;
		${mediaQuery.laptopXL`column-gap: 56px;`};
		${mediaQuery.mobileXL`grid-template-columns: 1fr;row-gap: 42px`};
	}
	${media.tablet`
		h1,,h3{
			font-size: ${setFontSize.mediumSmall};
		}
	`}
	${media.desktop`
		h1,h3{
			font-size: ${setFontSize.veryLarge};
		}
	`}
	${media.large`
		h1,h3{
			margin-top: ${props => (props.latest ? '93px' : '70px')};
			font-size: ${setFontSize.mediumLarge};
		}
	`}
`;

export const SubTitle = styled.h1`
	text-align: center;
	text-transform: capitalize;
	color: ${setColors.themeBlue} !important;
	font-weight: ${setFontWeight.extraBold} !important;
	line-height: 1.2 !important;
`;

export const Search = styled.h3`
	font-family: ${setFontFamily.book} !important;
	margin: 0;
	margin-top: 70px;
	color: ${setColors.lightBlack};
	margin-bottom: 50px;
	font-size: ${setFontSize.large};
	line-height: 1.2;
	font-weight: ${setFontWeight.extraBold};
`;

export const SearchTerm = styled.span`
	color: ${setColors.themeBlue};
`;
